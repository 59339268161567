const roles = {
  guest: "guest",
  superAdmin: 1,
  admin: 2,
  trainer: 3,
  office: 4,
};

const scopes = {
  admin: [roles.superAdmin, roles.admin, roles.office],
  trainer: [roles.superAdmin, roles.trainer],
  all: Object.values(roles).filter(r => r !== "guest"),
};

const permissions = { roles, scopes };

export default permissions;
