import { crudMdActions } from "@/services/crud-md-actions";
import apiClient from "@/services/ApiClient";

const name = "students";

export default {
  name,
  ...crudMdActions(name),
  getStudentData: studentId => apiClient.get(`/students/${studentId}`),
  updateStudentData: (studentId, payload) => apiClient.put(`/students/${studentId}`, payload),
  getCountries: () => apiClient.get("/countries/list-countries-options"),
  getAcademicTitles: () => apiClient.get("/academic-titles/list-academic-titles-options"),
  getCenters: () => apiClient.get("centers/list-centers-options"),
};
