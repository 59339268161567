import IntranetTabs from "@/layouts/intranet-tabs/Index.vue";
import IntranetSignMode from "@/layouts/intranet-sign-mode/Index.vue";

import trainerSessionsRoutes from "@/modules/cardioguard-trainer/router/trainerSessionsRoutes";
import trainerSessionSignaturesRoutes from "@/modules/cardioguard-trainer/router/trainerSessionSignaturesRoutes";
import trainerFinishedInscriptions from "@/modules/cardioguard-trainer/router/trainerFinishedInscriptionsRoutes";

export default [
  {
    path: "/trainer",
    component: IntranetTabs,
    children: [
      { path: "", redirect: { name: "trainerSessionsPending" } },
      ...trainerSessionsRoutes,
      ...trainerFinishedInscriptions,
    ],
  },
  {
    path: "/trainer",
    component: IntranetSignMode,
    children: [...trainerSessionSignaturesRoutes],
  },
];
