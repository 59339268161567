import apiClient from "@/services/ApiClient";

const name = "trainer-session-signatures";

export default {
  name,
  generateDocuments: (id, payload) => {
    return apiClient.post(`/trainer/training-course-sessions/${id}/generateDocuments`, payload);
  },
  getSessionSignaturesData: id => {
    return apiClient.get(`/trainer/training-course-sessions/${id}/pending-signatures`);
  },
  updateUserData: (id, payload) => {
    return apiClient.put(`/trainer/students/${id}`, payload);
  },
  submitAcceptTerms: (id, payload) => {
    return apiClient.post(`/trainer/assistances/${id}/accept-sign-terms`, payload);
  },
  checkStudentSignature: (sessionId, assistanceId) => {
    return apiClient.get(
      `/trainer/training-course-sessions/${sessionId}/check-assistance-signatures/${assistanceId}`
    );
  },
  checkTrainerSignature: sessionId => {
    return apiClient.get(`/trainer/training-course-sessions/${sessionId}/check-trainer-signatures`);
  },
  terminateSession: payload => {
    return apiClient.put("/trainer/training-course-sessions/status/finish", payload);
  },
};
