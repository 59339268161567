import permissions from "@/router/userPermissions";
import crudUpdateMd from "@/components/crud-md/actions/Edit";

export default [
  {
    path: "files/:id",
    name: "filesEdit",
    component: crudUpdateMd,
    meta: {
      auth: permissions.scopes.admin,
      serviceName: "files",
      title: "Archivo",
    },
  },
];
