import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import Cookies from "js-cookie";
import apiClient, { apiClientBlob } from "@/services/ApiClient";
import SecureLS from "secure-ls";
const ls = new SecureLS({ isCompression: false });

//Store Modules
//import CancelRequests from "@/store/modules/CancelRequests"

Vue.use(Vuex);

const defaultState = {
  currentService: null,
  currentTitle: null,
  loginRedirect: null,
  userSettings: {
    darkMode: false,
    fontSize: 1,
  },
  user: {
    loggedIn: null,
    trainerHasSignature: null,
  },
};

export default new Vuex.Store({
  modules: {
    //*CancelRequests,
  },
  plugins: [
    createPersistedState({
      key: "state",
      storage: {
        getItem: key => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: key => ls.remove(key),
      },
    }),
  ],
  state: defaultState,
  mutations: {
    UPDATE_TITLE(state, event) {
      state.currentTitle = event;
    },
    TOGGLE_DARK_MODE(state, event) {
      state.userSettings.darkMode = event;
    },
    TOGGLE_TRAINER_SIGNATURE_VALUE(state, event) {
      state.user.trainerHasSignature = event;
    },
    UPDATE_FONT_SIZE(state, event) {
      state.userSettings.fontsize = event;
    },
    SET_USER_DATA(state, loginData) {
      const { token } = loginData;
      delete loginData.token;

      const unix_timestamp = parseInt(loginData.expiry);
      const expiryDate = new Date(unix_timestamp * 1000);
      const dateNow = new Date();
      const timeToExpiry = expiryDate - dateNow;
      const daysToExpiry = Math.round((timeToExpiry / (1000 * 60 * 60) / 24) * 2) / 2;

      Cookies.set("auth_token", token, {
        expires: daysToExpiry,
        sameSite: "Lax",
        secure: true,
      });

      apiClient.defaults.headers.common["Http-Authorization"] = `Bearer ${token}`;
      apiClientBlob.defaults.headers.common["Http-Authorization"] = `Bearer ${token}`;

      state.user = { ...defaultState.user, ...loginData };
      state.user.loggedIn = true;
      createPersistedState();
    },
    UPDATE_CURRENT_SERVICE(state, newService) {
      state.currentService = newService;
    },
    CLEAR_USER_DATA(state) {
      Cookies.remove("auth_token");
      state.user = { ...defaultState.user };
      state.currentService = null;
      createPersistedState();
    },
  },
  actions: {
    updateTitle({ commit }, title) {
      commit("UPDATE_TITLE", title);
    },
    toggleDarkMode({ commit }, event) {
      commit("TOGGLE_DARK_MODE", event);
    },
    updateFontSize({ commit }, fontsize) {
      commit("UPDATE_FONT_SIZE", fontsize);
    },
    updateCurrentService({ commit }, newService) {
      commit("UPDATE_CURRENT_SERVICE", newService);
    },
    handleTrainerSignature({ commit }, event) {
      commit("TOGGLE_TRAINER_SIGNATURE_VALUE", event);
    },
    setUserData({ commit }, loginData) {
      commit("SET_USER_DATA", loginData);
    },
    logout({ commit }) {
      commit("CLEAR_USER_DATA");
      location.reload();
    },
  },
});
