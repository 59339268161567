import permissions from "@/router/userPermissions";

export default [
  {
    path: "sessions-pending",
    component: () => import("@/modules/cardioguard-trainer/views/TrainerSessionsPending"),
    name: "trainerSessionsPending",
    meta: {
      auth: permissions.scopes.trainer,
      title: "Sesiones",
      serviceName: "trainer-sessions",
    },
  },
  {
    path: "session/:id",
    component: () => import("@/modules/cardioguard-trainer/views/TrainerSession"),
    name: "trainerSession",
    meta: {
      auth: permissions.scopes.trainer,
      title: "Sesión",
      serviceName: "trainer-sessions",
    },
  },
];
