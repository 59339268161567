export default {
  data: () => ({
    isLoading: null,
    loadingColor: null,
    submitLoading: null,
    itemTitle: null,
    itemId: null,
    prevRoute: null,
    currentService: null,
    closeAfterSubmit: false,
  }),
  computed: {
    formId() {
      return this.currentService.name + "_" + this.itemId;
    },
  },
  methods: {
    closeItem() {
      const parentPathsNames = this.$route.meta.parentPathNames?.map(r => r.name || r) || [];
      const prevRouteParentPaths = this.prevRoute.meta.parentPathNames?.map(r => r.name || r) || [];
      const prevRoutes = [this.prevRoute?.name, ...prevRouteParentPaths];
      const comeFromElementsCrud = prevRoutes.some(route => parentPathsNames.includes(route));

      if (!comeFromElementsCrud && !!this.prevRoute?.name) {
        this.$router.push(this.prevRoute.fullPath);
      } else if (parentPathsNames.length) {
        this.$router.push({ name: parentPathsNames[parentPathsNames.length - 1] });
      } else {
        this.$router.push({ name: "dashboard" });
      }
    },
    async updateViewTitle() {
      await this.$store.dispatch("updateTitle", this.itemTitle);
    },
    setLoadingColor() {
      this.loadingColor = ["success", "error", "warning", "info"][Math.floor(Math.random() * 4)];
    },
    handleCloseAfterSubmit(value) {
      this.closeAfterSubmit = value;
    },
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from;
    });
  },
};
