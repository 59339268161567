<template>
  <div>
    <the-offline-dialog :dialog="offLineDialog" @handleOfflineDialog="handleOfflineDialog" />
    <router-view :fluid="fluid"></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  components: {
    TheOfflineDialog: () => import("@/components/TheOfflineDialog"),
  },
  data() {
    return {
      fluid: true,
      isOnLine: navigator.onLine,
      offLineDialog: false,
    };
  },
  methods: {
    handleOnlineStatus(e) {
      this.isOnLine = e.type === "online";
      this.handleOfflineDialog(!this.isOnLine);
    },
    handleOfflineDialog(active) {
      this.offLineDialog = active;
    },
  },
  mounted() {
    window.addEventListener("online", this.handleOnlineStatus);
    window.addEventListener("offline", this.handleOnlineStatus);
  },
  beforeDestroy() {
    window.removeEventListener("online", this.handleOnlineStatus);
    window.removeEventListener("offline", this.handleOnlineStatus);
  },
};
</script>

<style lang="scss">
/*@import "./scss/variables";*/
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $heading-font-family;
}
.subtitle-1,
.subtitle-2 {
  font-weight: bold !important;
}

.prd-dark-background {
  background: #121212;
}

.prd-light-background {
  background: white;
}
</style>
