import crudUpdateMd from "@/components/crud-md/actions/Edit";
import permissions from "@/router/userPermissions";

export default [
  {
    path: "business-groups",
    name: "businessGroups",
    component: () => import("@/modules/cardioguard-admin/views/BusinessGroupsCrud"),
    meta: {
      auth: permissions.scopes.admin,
      serviceName: "business-groups",
      title: "Grupos empresariales",
    },
  },
  {
    path: "business-groups/:id",
    name: "businessGroupsEdit",
    component: crudUpdateMd,
    meta: {
      auth: permissions.scopes.admin,
      parentPathNames: ["businessGroups"],
      serviceName: "business-groups",
      title: "Grupo",
    },
  },
];
