import permissions from "@/router/userPermissions";
import crudUpdateMd from "@/components/crud-md/actions/Edit";

export default [
  {
    path: "contacts",
    name: "contacts",
    component: () => import("@/modules/cardioguard-admin/views/ContactsCrud"),
    meta: { auth: permissions.scopes.admin, serviceName: "contacts", title: "Contactos" },
  },
  {
    path: "contacts/:id",
    name: "contactsEdit",
    component: crudUpdateMd,
    meta: {
      auth: permissions.scopes.admin,
      parentPathNames: ["contacts"],
      serviceName: "contacts",
      title: "Ficha de contacto",
    },
  },
];
