import apiClient, { apiClientBlob } from "@/services/ApiClient";

const name = "trainer-sessions";

export default {
  name,
  getPendingSessions: () => apiClient.get(`trainer/training-course-sessions/pending`),
  getSessionData: id => apiClient.get(`/trainer/training-course-sessions/${id}`),
  handleAssistance: params => {
    return apiClient.put("/trainer/training-courses/assistance/assisted", params);
  },
  searchStudentByTin: args => {
    return apiClient.get(
      `/trainer/training-course-sessions/${args.sessionId}/search-student?tin=${args.tin}`
    );
  },
  addAssistant: payload => apiClient.post("/trainer/training-course-inscriptions", payload),
  addNewStudentAssistant: payload => {
    return apiClient.post("/trainer/training-course-inscriptions/new-student", payload);
  },
  updateStudentComment: payload => {
    return apiClient.put("/trainer/training-course-inscriptions/comment", payload);
  },
  updateStudentTin: payload => {
    return apiClient.put("/trainer/training-course-inscriptions/tin", payload);
  },
  postTestData: payload => {
    return apiClient.post("/trainer/form-records", payload);
  },
  getBiometricSignedDocuments: sessionId => {
    return apiClient.get(`/trainer/training-course-sessions/${sessionId}/syncBiometricDocuments`);
  },
  getFile: (sessionId, docId) => {
    return apiClientBlob.get(
      `/trainer/training-course-sessions/${sessionId}/documents/${docId}/download`
    );
  },
  uploadTrainerSignature: signature => {
    return apiClient.post("/trainer/upload-trainer-signature", signature);
  },
};
