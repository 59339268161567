import apiClient, { apiClientBlob } from "@/services/ApiClient";

export function importActions(path) {
  const apiBase = `${process.env.VUE_APP_API_BASE_PATH}` || "/api";
  const getUploadingStatus = () => apiClient.get(`${path}/items/import/status`);
  const downloadImportExcelTemplate = () => apiClientBlob.get(`${path}/items/import/template`);
  //getImportPath for Dropzone Lib
  const getImportPath = () => `${apiBase}${path}/items/import`;

  return { getUploadingStatus, downloadImportExcelTemplate, getImportPath };
}
