import { crudMdActions } from "@/services/crud-md-actions";
import apiClient from "@/services/ApiClient";

const name = "training-courses";

export default {
  name,
  ...crudMdActions(name),
  getTrainingCourseData: trainingCourseId => apiClient.get(`/training-courses/${trainingCourseId}`),
  updateTrainingCourseData: (trainingCourseId, payload) => {
    return apiClient.put(`/training-courses/${trainingCourseId}`, payload);
  },
  getTrainingCourseCategories: (trainingCourseId) => {
    return apiClient.get(
      "/training-course-categories/list-training-course-categories-options?training_course_id=" +
        trainingCourseId
    );
  },
  getDirectors: (trainingCourseId) => {
    return apiClient.get("/directors/list-directors-options?training_course_id=" + trainingCourseId);
  },
  getCentersContacts: centersIdArr => {
    let path = `/contacts/list-contacts-options?centers=${centersIdArr.join(",")}`;
    return apiClient.get(path);
  },
  getOrganizationCompanies: (trainingCourseId) => {
    return apiClient.get(
      "/organization-companies/list-organization-companies-options?training_course_id=" +
        trainingCourseId
    );
  },
  getCoordinators: () => {
    return apiClient.get("/coordinators/list-coordinators-options");
  },
  getLocalizations: () => apiClient.get("localizations/list-localizations-options"),
  getCourseStatus: () => apiClient.get("/training-courses/list-training-course-status-options"),
  getCourseModalities: () => apiClient.get("/training-courses/list-training-modalities-options"),
  getCenters: () => apiClient.get("centers/list-centers-options"),
  activateTrainingCourse: courseId => apiClient.put(`training-courses/${courseId}/activate`),
  closeTrainingCourse: courseId => apiClient.put(`training-courses/${courseId}/close`),
  generateCourseCertificates: courseId => {
    return apiClient.put(`training-courses/${courseId}/generate-certificates`);
  },
  regenerateCourseGroupalCertificates: courseId => {
    return apiClient.put(`training-courses/${courseId}/regenerate-groupal-certificates`);
  },
};
