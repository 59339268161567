import apiClient from "@/services/ApiClient";

export function createActions(path) {
  const getCreateItemForm = params => {
    if (params) {
      let queryParams = "?";
      queryParams += Object.keys(params)
        .map(key => `${key}=${params[key]}`)
        .join("&");
      return apiClient.get(`${path}/new${queryParams}`);
    } else {
      return apiClient.get(`${path}/new`);
    }
  };
  const createItem = data => apiClient.post(`${path}`, data);

  return { getCreateItemForm, createItem };
}
