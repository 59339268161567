import permissions from "@/router/userPermissions";
import trainingCoursesRoutesTabs from "@/modules/cardioguard-admin/router/trainingCoursesRoutesTabs";

export default [
  {
    path: "training-courses",
    name: "trainingCourses",
    component: () => import("@/modules/cardioguard-admin/views/trainingCoursesCrud"),
    meta: {
      auth: permissions.scopes.admin,
      serviceName: "training-courses",
      title: "Formaciones",
    },
  },

  {
    path: "training-courses/:id/edit",
    name: "trainingCoursesEdit",
    component: () => {
      return import(
        "@/modules/cardioguard-admin/views/trainingCoursesCrud/TrainingCoursesCrudEdit"
      );
    },
    meta: {
      auth: permissions.scopes.admin,
      parentPathNames: ["trainingCourses"],
      serviceName: "training-courses",
      tabs: trainingCoursesRoutesTabs,
      title: "Formación",
    },
  },

  {
    path: "training-courses/:id/sessions",
    component: () =>
      import("@/modules/cardioguard-admin/views/trainingCoursesCrud/TrainingCoursesCrudSessions"),
    name: "trainingCourses-trainingCourseSessions",
    meta: {
      auth: permissions.scopes.admin,
      parentPathNames: ["trainingCourses"],
      title: "Formación - Sesiones",
      tabs: trainingCoursesRoutesTabs,
      serviceName: "training-course-sessions",
    },
  },

  {
    path: "training-courses/:id/inscriptions",
    component: () =>
      import(
        "@/modules/cardioguard-admin/views/trainingCoursesCrud/TrainingCoursesCrudInscriptions"
      ),
    name: "trainingCourses-trainingCourseInscriptions",
    meta: {
      auth: permissions.scopes.admin,
      parentPathNames: ["trainingCourses"],
      title: "Formación - Participantes",
      tabs: trainingCoursesRoutesTabs,
      serviceName: "training-course-inscriptions",
    },
  },

  {
    path: "training-courses/:id/files",
    component: () =>
      import("@/modules/cardioguard-admin/views/trainingCoursesCrud/TrainingCoursesCrudFiles"),
    name: "trainingCourses-files",
    meta: {
      auth: permissions.scopes.admin,
      parentPathNames: ["trainingCourses"],
      title: "Formación - Documentos",
      tabs: trainingCoursesRoutesTabs,
      serviceName: "files",
    },
  },

  {
    path: "training-courses/:id/documents",
    component: () =>
      import("@/modules/cardioguard-admin/views/trainingCoursesCrud/TrainingCoursesCrudDocuments"),
    name: "trainingCourses-signedDocuments",
    meta: {
      auth: permissions.scopes.admin,
      parentPathNames: ["trainingCourses"],
      title: "Formación - Documentos",
      tabs: trainingCoursesRoutesTabs,
      serviceName: "documents",
    },
  },

  {
    path: "training-courses/:id/client-notices",
    component: () =>
      import(
        "@/modules/cardioguard-admin/views/trainingCoursesCrud/TrainingCoursesCrudClientNotices"
      ),
    name: "trainingCourses-clientNotices",
    meta: {
      auth: permissions.scopes.admin,
      parentPathNames: ["trainingCourses"],
      title: "Formación - Avisos",
      tabs: trainingCoursesRoutesTabs,
      serviceName: "client-notices",
    },
  },
];
