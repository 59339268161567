import { editActions } from "@/services/crud-md-actions/editActions";
import { createActions } from "@/services/crud-md-actions/createActions";
import { getListActions } from "@/services/crud-md-actions/getListActions";
import { importActions } from "@/services/crud-md-actions/importActions";
import { exportActions } from "@/services/crud-md-actions/exportActions";
import { deleteActions } from "@/services/crud-md-actions/deleteActions";
import { configActions } from "@/services/crud-md-actions/configActions";

export function crudMdActions(name, customPath = null) {
  let basePath = "/";
  basePath += customPath || name;

  return {
    ...getListActions(basePath),
    ...createActions(basePath),
    ...editActions(basePath),
    ...deleteActions(basePath),
    ...exportActions(basePath),
    ...importActions(basePath),
    ...configActions(basePath),
  };
}
