import permissions from "@/router/userPermissions";
import crudUpdateMd from "@/components/crud-md/actions/Edit";

export default [
  { path: "settings", name: "settings", redirect: "trainingCourseCategory" },
  {
    path: "settings/training-course-categories",
    name: "trainingCourseCategory",
    component: () => import("@/modules/cardioguard-admin/views/TrainingCourseCategoriesCrud"),
    meta: {
      auth: permissions.scopes.admin,
      parentPathNames: [{ name: "settings", breadcrumbDisabled: true }],
      serviceName: "training-course-categories",
      title: "Tipo de formaciones",
    },
  },
  {
    path: "settings/training-course-categories/:id",
    name: "trainingCourseCategoryEdit",
    component: crudUpdateMd,
    meta: {
      auth: permissions.scopes.admin,
      parentPathNames: [{ name: "settings", breadcrumbDisabled: true }, "trainingCourseCategory"],
      serviceName: "training-course-categories",
      title: "Tipo de formación",
    },
  },

  {
    path: "settings/localizations",
    name: "localizations",
    component: () => import("@/modules/cardioguard-admin/views/LocalizationsCrud"),
    meta: {
      auth: permissions.scopes.admin,
      parentPathNames: [{ name: "settings", breadcrumbDisabled: true }],
      serviceName: "localizations",
      title: "Localizaciones",
    },
  },
  {
    path: "settings/localizations/:id",
    name: "localizationsEdit",
    component: crudUpdateMd,
    meta: {
      auth: permissions.scopes.admin,
      parentPathNames: [{ name: "settings", breadcrumbDisabled: true }, "localizations"],
      serviceName: "localizations",
      title: "Localización",
    },
  },

  {
    path: "settings/email-templates",
    name: "emailTemplates",
    component: () => import("@/modules/cardioguard-admin/views/EmailTemplatesCrud"),
    meta: {
      auth: permissions.scopes.admin,
      parentPathNames: [{ name: "settings", breadcrumbDisabled: true }],
      serviceName: "mailing-templates",
      title: "Plantillas de correo",
    },
  },
  {
    path: "settings/email-templates/:id",
    name: "emailTemplatesEdit",
    component: crudUpdateMd,
    meta: {
      auth: permissions.scopes.admin,
      parentPathNames: [{ name: "settings", breadcrumbDisabled: true }, "emailTemplates"],
      serviceName: "mailing-templates",
      title: "Plantilla de correo",
    },
  },

  {
    path: "settings/document-templates",
    name: "documentTemplates",
    component: () => import("@/modules/cardioguard-admin/views/DocumentTemplatesCrud"),
    meta: {
      auth: permissions.scopes.admin,
      parentPathNames: [{ name: "settings", breadcrumbDisabled: true }],
      serviceName: "document-templates",
      title: "Plantillas de documentos",
    },
  },
  {
    path: "settings/document-templates/:id",
    name: "documentTemplatesEdit",
    component: crudUpdateMd,
    meta: {
      auth: permissions.scopes.admin,
      parentPathNames: [{ name: "settings", breadcrumbDisabled: true }, "documentTemplates"],
      serviceName: "document-templates",
      title: "Documento",
    },
  },

  {
    path: "settings/organization-companies",
    name: "organizationalCompanies",
    component: () => import("@/modules/cardioguard-admin/views/OrganizationCompaniesCrud"),
    meta: {
      auth: permissions.scopes.admin,
      serviceName: "organization-companies",
      title: "Entidades organizativas",
    },
  },
  {
    path: "settings/organization-companies/:id",
    name: "organizationalCompaniesEdit",
    component: crudUpdateMd,
    meta: {
      auth: permissions.scopes.admin,
      parentPathNames: [{ name: "settings", breadcrumbDisabled: true }, "organizationalCompanies"],
      serviceName: "organization-companies",
      title: "Entidad organizativa",
    },
  },

  {
    path: "settings/trainers",
    name: "trainers",
    component: () => import("@/modules/cardioguard-admin/views/TrainersCrud"),
    meta: {
      auth: permissions.scopes.admin,
      parentPathNames: [{ name: "settings", breadcrumbDisabled: true }],
      serviceName: "trainers",
      title: "Formadores",
    },
  },
  {
    path: "settings/trainers/:id",
    name: "trainersEdit",
    component: crudUpdateMd,
    meta: {
      auth: permissions.scopes.admin,
      parentPathNames: [{ name: "settings", breadcrumbDisabled: true }, "trainers"],
      serviceName: "trainers",
      title: "Formador",
    },
  },

  {
    path: "settings/directors",
    name: "directors",
    component: () => import("@/modules/cardioguard-admin/views/DirectorsCrud"),
    meta: {
      auth: permissions.scopes.admin,
      parentPathNames: [{ name: "settings", breadcrumbDisabled: true }],
      serviceName: "directors",
      title: "Directores",
    },
  },
  {
    path: "settings/directors/:id",
    name: "directorsEdit",
    component: crudUpdateMd,
    meta: {
      auth: permissions.scopes.admin,
      parentPathNames: [{ name: "settings", breadcrumbDisabled: true }, "directors"],
      serviceName: "directors",
      title: "Director",
    },
  },

  {
    path: "settings/country-regions",
    name: "countryRegions",
    component: () => import("@/modules/cardioguard-admin/views/TrainersCrud"),
    meta: {
      auth: permissions.scopes.admin,
      parentPathNames: [{ name: "settings", breadcrumbDisabled: true }],
      serviceName: "country-regions",
      title: "Comunidades autónomas",
    },
  },
  {
    path: "settings/country-regions/:id",
    name: "countryRegionsEdit",
    component: crudUpdateMd,
    meta: {
      auth: permissions.scopes.admin,
      parentPathNames: [{ name: "settings", breadcrumbDisabled: true }, "countryRegions"],
      serviceName: "country-regions",
      title: "Comunidad autónoma",
    },
  },
];
