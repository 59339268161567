import permissions from "@/router/userPermissions";

export function userHasScope(scopeName, roles) {
  const scope = permissions.scopes[scopeName];
  if (!scope) return undefined;
  const commonRoles = roles.filter(value => scope.includes(value));

  return !!commonRoles.length;
}

export function isUrlScope(urlScope) {
  const path = new URL(window.location.href).pathname;
  return path.startsWith(urlScope);
}

export function downloadFile(response, fileName = null) {
  let newFileName = "";
  const contentDispositionHeader = response.headers["content-disposition"];
  if (contentDispositionHeader) {
    const startFileNameIndex = contentDispositionHeader.indexOf('"') + 1;
    const endFileNameIndex = contentDispositionHeader.lastIndexOf('"');
    newFileName += contentDispositionHeader.substring(startFileNameIndex, endFileNameIndex);
  }

  const blobParts = [response.data];
  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(new Blob(blobParts));
  link.setAttribute("download", `${newFileName || fileName}`);
  link.setAttribute("target", "_blank");
  document.body.appendChild(link);
  link.click();
  link.remove();
}

export function slugify(string) {
  const a = "àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìıİłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;";
  const b = "aaaaaaaaaacccddeeeeeeeegghiiiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------";
  const p = new RegExp(a.split("").join("|"), "g");

  return string
    .toString()
    .toLowerCase()
    .replace(/\s+/g, "-") // Replace spaces with -
    .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, "-and-") // Replace & with 'and'
    .replace(/[^\w-]+/g, "") // Remove all non-word characters
    .replace(/--+/g, "-") // Replace multiple - with single -
    .replace(/^-+/, "") // Trim - from start of text
    .replace(/-+$/, ""); // Trim - from end of text
}

export function cleanTin(string) {
  return string ? string.replace(" ", "").toUpperCase() : undefined;
}

const genders = [
  { text: "Masculino", value: "male" },
  { text: "Femenino", value: "female" },
  { text: "Otro", value: "other" },
];
export default genders;
