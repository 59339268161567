import permissions from "@/router/userPermissions";

export default [
  {
    path: "finished-training-course-inscriptions",
    component: () => {
      return import("@/modules/cardioguard-trainer/views/TrainerFinishedCourseInscriptions");
    },
    name: "trainerFinishedInscriptions",
    meta: {
      auth: permissions.scopes.trainer,
      title: "Evaluaciones de cursos finalizados",
      serviceName: "finished-training-course-inscriptions",
    },
  },
  {
    path: "finished-training-course-inscriptions/:id",
    component: () => {
      return import("@/modules/cardioguard-trainer/views/TrainerFinishedCourseInscriptionsEdit");
    },
    name: "trainerFinishedInscriptionsEdit",
    meta: {
      auth: permissions.scopes.trainer,
      title: "Evaluación de alumno",
      serviceName: "finished-training-course-inscriptions",
    },
  },
];
