import businessGroupsService from "@/modules/cardioguard-admin/services/businessGroupsService";
import centersService from "@/modules/cardioguard-admin/services/centersService";
import contactsService from "@/modules/cardioguard-admin/services/contactsService";
import certificatesService from "@/modules/cardioguard-admin/services/certificatesService";
import clientsService from "@/modules/cardioguard-admin/services/clientsService";
import clientNoticesService from "@/modules/cardioguard-admin/services/clientNoticesService";
import countryRegionsService from "@/modules/cardioguard-admin/services/countryRegionsService";
import dashboardServices from "@/modules/cardioguard-admin/services/dashboardService";
import directorsService from "@/modules/cardioguard-admin/services/directorsService";
import docTemplatesService from "@/modules/cardioguard-admin/services/documentTemplatesService";
import docsService from "@/modules/cardioguard-admin/services/documentsService";
import emailTemplatesService from "@/modules/cardioguard-admin/services/emailTemplatesService";
import filesService from "@/modules/cardioguard-admin/services/filesService";
import localizationsService from "@/modules/cardioguard-admin/services/localizationsService";
import organizationCompaniesService from "@/modules/cardioguard-admin/services/organizationCompaniesService";
import sessionsService from "@/modules/cardioguard-admin/services/sessionsService";
import studentsService from "@/modules/cardioguard-admin/services/studentsService";
import trainerUsersService from "@/modules/cardioguard-admin/services/trainersService";
import trainingCourseCategories from "@/modules/cardioguard-admin/services/tTrainingCourseCategoriesService";
import trainingCoursesInscriptionsService from "@/modules/cardioguard-admin/services/trainingCoursesInscriptionsService";
import trainingCoursesService from "@/modules/cardioguard-admin/services/trainingCoursesService";
import trainingCoursesSessionsService from "@/modules/cardioguard-admin/services/trainingCoursesSessionsService";
import userService from "@/modules/cardioguard-admin/services/userService";

export default [
  businessGroupsService,
  centersService,
  certificatesService,
  clientsService,
  clientNoticesService,
  contactsService,
  countryRegionsService,
  dashboardServices,
  directorsService,
  docTemplatesService,
  docsService,
  emailTemplatesService,
  filesService,
  localizationsService,
  organizationCompaniesService,
  sessionsService,
  studentsService,
  trainingCourseCategories,
  trainingCoursesService,
  trainingCoursesInscriptionsService,
  trainingCoursesSessionsService,
  trainerUsersService,
  userService,
];
